
import { defineComponent, onMounted, ref, watch } from "vue";
// import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { DiseaseListModel } from "@/domain/disease/diseaseList/model/DiseaseListModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const diseaseList = ref<DiseaseListModel[]>([]);
    const originalDiseaseList = ref<DiseaseListModel[]>([]);

    const isLoading = ref<boolean>(false);

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const diseaseController = store.state.ControllersModule.diseaseController;

    watch(
      () => store.getters.newDisease,
      () => {
        if (store.getters.newDisease) getDiseases(1);
      }
    );

    const getDiseases = (page) => {
      isLoading.value = true;
      diseaseList.value = [];
      originalDiseaseList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const diseaseListModel: DiseaseListModel = {
        disease: {
          id: 0,
        },
        page: page,
        pageSize: pageSize.value,
      };

      diseaseController
        .diseaseList(diseaseListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((disease) => {
              totalItems.value = disease.pagination.total;
              currentPage.value = disease.pagination.page;
              pageCount.value = disease.pagination.pageCount;
              diseaseList.value.push(disease);
              originalDiseaseList.value.push(disease);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.DISEASE));
      getDiseases(1);
    });

    return {
      PAGE_TITLE_PATH,
      getDiseases,
      diseaseList,
      originalDiseaseList,
      totalItems,
      currentPage,
      pageCount,
      pageSize,
      isLoading,
    };
  },
});
